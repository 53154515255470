import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import configData from '../config.json';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Head from "../components/Head";
import Tail from "../components/Tail";
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';


function BankPage() {
  const { t } = useTranslation();
  const [isSessionOpen, setIsSessionOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  const [selectedNavItem, setSelectedNavItem] = useState('0');
  const [topicList, setTopicList] = useState([]);
  const [todaySessionList, setTodaySessionList] = useState([]);
  const [lastWeekSessionList, setLastWeekSessionList] = useState([]);
  const [earlierSessionList, setEarlierSessionList] = useState([]);

  const userId = localStorage.getItem('userid');

  const apiGetTopicHistory = (keyWord, beforeBy) => {
    fetch(configData.API_URL + `/tutor/topics/?topicType=Chemical&keyWord=${keyWord}&beforeBy=${beforeBy}`,
      {
        redirect: 'manual',
        headers: {
          'Authorization': `Bearer ${userId}`,
          'Content-Type': 'application/json',
        },
      })
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        if (data.success) {
          const tmpTopics = data.topics.map(t => {return { ...t, createDate: new Date(t.createDate) };});
          setTopicList(tmpTopics);
        } else {
          console.log(data);
        }
      })
      .catch(error => console.log(error));
  }

  const apiDeleteTopic = (topicId) => {
    fetch(configData.API_URL + `/tutor/topic/${topicId}`, {
      method: 'DELETE',
      credentials: 'include',
      redirect: 'manual',
      headers: {
        'Authorization': `Bearer ${userId}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        alert(data.message);
        if (data.success) {
          const myArray = topicList.filter(obj => obj._id !== topicId);
          setTopicList(myArray);
        }
      })
      .catch(error => console.log(error));
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);

    apiGetTopicHistory('', '');

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  useEffect(() => {
    let todaySessions = [];
    let lastWeekSessions = [];
    let earlierSessions = [];

    const now = moment(); // 当前时间

    topicList.forEach(topic => {
      let sessionDate = moment(topic.createDate);

      if (sessionDate.isSame(now, 'day')) {
        todaySessions.push(topic);
      } else if (sessionDate.isBetween(now.clone().subtract(7, 'days'), now)) {
        lastWeekSessions.push(topic);
      } else {
        earlierSessions.push(topic);
      }
    });

    setTodaySessionList(todaySessions);
    setLastWeekSessionList(lastWeekSessions);
    setEarlierSessionList(earlierSessions);
  }, [topicList]);

  function onDeleteTopic(event, topicId) {
    event.preventDefault();
    // console.log('to delete topic', topicId);
    var userConfirmed = window.confirm(`Do you want to delete this topic and all content under it?`);

    if (userConfirmed) {
      apiDeleteTopic(topicId);
    }
  }

  function onSessionSelect(eventKey) {
    // console.log('selected', eventKey);
    sessionStorage.setItem('currentTopicId', eventKey);
    navigate('/mytutor');
  }

  const toggleSessionList = () => setIsSessionOpen(!isSessionOpen);

  return (
    <div>
      <Head />
      <div className='main-container'>
        <div className='container-center screen-height'>

          <div className='sub-right-container'>

            <Collapse in={isSessionOpen}>
              <div className='sub-left d-sm-block'>
                <div>
                  <img className='tutor-avatar' src='/images/chemical-tutor.png' alt=''></img>
                  <p>Hi, I am your Chemical tutor</p>
                  <p>You can choose a historical topic to review.</p>
                </div>
              </div>
            </Collapse>

            <div className='sub-right flex-grow-1'>
              {windowWidth < 576 && (
                <Button className='btn-menu' variant='success' onClick={toggleSessionList}>
                  {isSessionOpen ? '<' : '>'}
                </Button>
              )}

              {/* -- Topic history -- */}
              <Nav className='flex-column' onSelect={onSessionSelect} activeKey={selectedNavItem}>
                <p className='session-group-title'> {t('reading_group_today')} </p>
                {todaySessionList.map((session, index) => (
                  <Nav.Item key={index}>
                    <Container>
                      <Row>
                        <Col>
                          <Nav.Link eventKey={session['_id']} style={{ color: 'grey', fontWeight: '500' }}>
                            <Row> <p className='bank-topic-text'> &#8226; {session['content']} </p> </Row>
                            <Row> <p className='chat-box-text-time'> &nbsp;&nbsp; { session.createDate.toLocaleString() }</p> </Row>
                          </Nav.Link>
                        </Col>
                        <Col xs={windowWidth < 576 ? 2: 1}>
                          <Button variant="outline-secondary" onClick={(event) => { onDeleteTopic(event, session['_id']) }}>
                            <Image className='bank-btn-del' src="/images/trash-can-regular.svg" rounded />
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  </Nav.Item>
                ))}

                <p className='session-group-title'> {t('reading_group_week')} </p>
                {lastWeekSessionList.map((session, index) => (
                  <Nav.Item key={index}>
                    <Container>
                      <Row>
                        <Col>
                          <Nav.Link eventKey={session['_id']} style={{ color: 'grey', fontWeight: '500' }}>
                            <Row> <p className='bank-topic-text'> &#8226; {session['content']} </p> </Row>
                            <Row> <p className='chat-box-text-time'> &nbsp;&nbsp; { session.createDate.toLocaleString() }</p> </Row>
                          </Nav.Link>
                        </Col>
                        <Col xs={windowWidth < 576 ? 2: 1}>
                          <Button variant="outline-secondary" onClick={(event) => { onDeleteTopic(event, session['_id']) }}>
                            <Image className='bank-btn-del' src="/images/trash-can-regular.svg" rounded />
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  </Nav.Item>
                ))}

                <p className='session-group-title'> {t('reading_group_early')} </p>
                {earlierSessionList.map((session, index) => (
                  <Nav.Item key={index}>
                    <Container>
                      <Row>
                        <Col>
                          <Nav.Link eventKey={session['_id']} style={{ color: 'grey', fontWeight: '500' }}>
                            <Row> <p className='bank-topic-text'> &#8226; {session['content']} </p> </Row>
                            <Row>  <p className='chat-box-text-time'> &nbsp;&nbsp; { session.createDate.toLocaleString() }</p> </Row>
                          </Nav.Link>
                        </Col>
                        <Col xs={windowWidth < 576 ? 2: 1}>
                          <Button variant="outline-secondary" onClick={(event) => { onDeleteTopic(event, session['_id']) }}>
                            <Image className='bank-btn-del' src="/images/trash-can-regular.svg" rounded />
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  </Nav.Item>
                ))}
              </Nav>

            </div>
          </div>

        </div>
      </div>
      <Tail />
    </div>
  );
}

export default BankPage;
