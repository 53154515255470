import React, { useState, useRef } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


const ImageCropper = ({ modalShow, onClose, onCropComplete }) => {
  const imageSrcRef = useRef(null);
  const imageInputRef = useRef(null);
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({ width: 0, height: 0 });

  const onImageLoad = (imageFile) => {
    setSrc(URL.createObjectURL(imageFile));
  };

  const handleCrop = async () => {
    const croppedImageBlob = await getCroppedImg(crop);
    // Pass the cropped image data to the parent component
    onCropComplete(croppedImageBlob);
  };

  const getCroppedImg = (crop) => {
    // console.log(crop);
    let x = 0;
    let y = 0;
    let w = imageSrcRef.current.naturalWidth;
    let h = imageSrcRef.current.naturalHeight;

    const scaleX = imageSrcRef.current.naturalWidth / imageSrcRef.current.width;
    const scaleY = imageSrcRef.current.naturalHeight / imageSrcRef.current.height;

    if (crop.width != 0 && crop.height != 0) {
      x = crop.x * scaleX;
      y = crop.y * scaleY;
      w = crop.width * scaleX;
      h = crop.height * scaleY;
    }

    const canvas = document.createElement('canvas');
    canvas.width = w;
    canvas.height = h;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      imageSrcRef.current,
      x, y, w, h,
      0, 0, w, h
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }
        resolve(blob);
      }, 'image/png');
    });
  };


  return (
    <div>

      <Modal show={modalShow} size="lg" aria-labelledby="contained-modal-title-vcenter" centered style={
        { width: '100%', maxWidth: '1000px' }
      }>
        <Modal.Header>
          <h6> Select an image or crop part of it </h6>
        </Modal.Header>
        <Modal.Body>
          <ReactCrop crop={crop} onChange={c => setCrop(c)} onComplete={() => { }}>
            <img src={src} ref={imageSrcRef} />
          </ReactCrop>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => {
            imageInputRef.current.click();
          }} variant="outline-success"> Choose </Button>
          <input type='file' ref={imageInputRef} style={{ display: "none" }} onChange={(e) => onImageLoad(e.target.files[0])} />
          <Button onClick={handleCrop} variant="outline-success"> Confirm </Button>
          <Button onClick={() => { onClose(); }} variant="outline-success"> Cancel </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default ImageCropper;
