import { useEffect, useState } from 'react';
import configData from '../config.json';

import Tail from "../components/Tail";
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const handleUsernameChange = (event) => {
    let username = event.target.value;
    setUsername(username.trim());
  }

  const handlePasswordChange = (event) => {
    let password = event.target.value;
    setPassword(password.trim());
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const requestData = {
      username: username.trim(),
      password: password.trim(),
    };

    fetch(configData.API_URL + '/user/login', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData)
    })
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        if (data.success) {
          const nickname = data.user.firstName ? data.user.firstName : data.user.username;
          localStorage.setItem('username', data.user.username);
          localStorage.setItem('nickname', nickname);
          localStorage.setItem('email', data.user.email);
          localStorage.setItem('userid', data.user.token);
          // jump to main page
          window.location.replace('/');
        } else {
          alert(data.message);
        }
      })
      .catch(error => console.log(error));
  }

  return (
    <div className='main-container'>
      <div className='background'>

        {windowWidth > 576 && (
          <div className='welcome-desc'>
            <h2>Welcome to use Mangosteen tutor account</h2>
            <br />
            <h4> &nbsp; Your exclusive personal tutor</h4>
            <br />
            <h5> &nbsp; - Solve any questions you have </h5>
            <h5> &nbsp; - Any time, get rid of the constraints of time </h5>
            <h5> &nbsp; - Supplement your learning blind spots </h5>
          </div>
        )}

        <div className='login'>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className='login-label'>Mobile or Email</Form.Label>
              <Form.Control type="text" placeholder="Enter your mobile or email" value={username} onChange={handleUsernameChange} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label className='login-label'>Password</Form.Label>
              <Form.Control type="password" placeholder="Enter your password" value={password} onChange={handlePasswordChange} />
            </Form.Group>

            <Button variant="outline-success" type="submit" >Login</Button>
          </Form>
          <Nav.Link href="/register" className='login-reg'>I don't have an account yet</Nav.Link>
        </div>
      </div>

      <Tail />
    </div>
  );
}

export default LoginPage;
